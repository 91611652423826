import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Typography } from '@material-ui/core';

import AppContainer from '../Components/AppContainer';
import MadeWithLove from '../Components/MadeWithLove';

import withStyle from '../style';

export default function Calibration({match}) {
  const classes = withStyle();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Calibration" match={match}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3" align="center" gutterBottom>
            Hello World
          </Typography>
        </Container>
        <MadeWithLove />
      </AppContainer>
    </div>
  );
}